import P5 from "p5";

enum Dir {
  Left,
  Right,
  Top,
  Bottom
}

enum CollisionType {
  Eat,
  Deadly,
  Margins,
  None
}

interface Snake {
  x: number;
  y: number;
  dir: Dir;
  next: Snake | null;
}

interface Food {
  x: number;
  y: number;
}

const winWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const winHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

function makeRandomFood(tableSize: number): Food {
  return {
    x: Math.floor(Math.random() * tableSize),
    y: Math.floor(Math.random() * tableSize)
  };
}

const main = (p: P5) => {
  let tableSize = 30;
  let playing = true;
  let snake: Snake = {
    x: tableSize / 2,
    y: tableSize / 2,
    dir: Dir.Left,
    next: null
  };
  let food: Food = makeRandomFood(tableSize);
  let score = 0;
  const gameSize = Math.min(400, winHeight, winWidth);
  const scoreButton = p.createButton("" + score);
  const restart = p.createButton("Restart");
  const makeDirButton = (
    label: string,
    x: number,
    y: number,
    dir: Dir,
    ndir: Dir
  ) => {
    const button1 = p.createButton(label);
    button1.position(x, y);
    button1.style("font-family", "Bodoni");
    button1.style("font-size", "20px");
    button1.style("background", "white");
    button1.style("color", "black");
    button1.mousePressed(() => {
      if (snake.dir !== ndir) {
        snake.dir = dir;
      }
    });
  };

  const ChoseDifficultyButton = (
    label: string,
    x: number,
    y: number,
    ts: tableSize,
    frameRate: frameRate
  ) => {
    const button3 = p.createButton(label);
    button3.position(x, y);
    button3.style("font-size", "20px");
    button3.style("background", "white");
    button3.style("color", "black");
    button3.mousePressed(() => {
      tableSize = ts;
      p.frameRate(frameRate);
      playing = true;
      snake = { x: ts / 2, y: ts / 2, dir: Dir.Left, next: null };
      food = makeRandomFood(ts);
      score = 0;
      console.log(tableSize);
    });
  };

  p.setup = () => {
    p.createCanvas(gameSize, gameSize);
    p.frameRate(5);
    makeDirButton("◁", 65, gameSize + 40, Dir.Left, Dir.Right);
    makeDirButton("▷", 140, gameSize + 40, Dir.Right, Dir.Left);
    makeDirButton("△", 100, gameSize, Dir.Top, Dir.Bottom);
    makeDirButton("▽", 100, gameSize + 80, Dir.Bottom, Dir.Top);
    // ChoseDifficultyButton("easy", 400, 100, 20, 7);
    // ChoseDifficultyButton("medium", 400, 130, 30, 10);
    // ChoseDifficultyButton("hard", 400, 160, 40, 15);

    restart.position(gameSize / 2 - 30, gameSize / 2 - 10);
    restart.style("font-size", "20px");
    restart.style("background", "White");
    restart.style("color", "black");
    restart.hide();
    restart.mousePressed(() => {
      restartGame();
    });

    scoreButton.position(250, gameSize + 10);
    scoreButton.style("font-size", "20px");
    scoreButton.style("background", "white");
    scoreButton.style("color", "black");
  };

  function restartGame() {
    playing = true;
    snake = { x: 25, y: 25, dir: Dir.Left, next: null };
    food = makeRandomFood(tableSize);
    score = 0;
    restart.hide();
  }

  p.draw = () => {
    p.background("black");
    if (playing === true) {
      updatePos(snake, snake.dir);
      const col = detectCollision(snake, food, tableSize);
      reactOnCollision(col);
      drawFood();
    }
    drawSnake();
    scoreButton.html("" + score);
  };

  const updatePos = (s: Snake, dir: Dir) => {
    sendPos(s.next, s.x, s.y);
    switch (dir) {
      case Dir.Left:
        s.x--;
        break;

      case Dir.Right:
        s.x++;
        break;

      case Dir.Top:
        s.y--;
        break;

      case Dir.Bottom:
        s.y++;
        break;
    }
  };

  function sendPos(s: Snake, x, y) {
    if (s != null) {
      sendPos(s.next, s.x, s.y);
      s.x = x;
      s.y = y;
    }
  }
  p.keyPressed = () => {
    switch (p.keyCode) {
      case p.LEFT_ARROW:
        if (snake.dir !== Dir.Right) {
          snake.dir = Dir.Left;
        }
        break;

      case p.RIGHT_ARROW:
        if (snake.dir !== Dir.Left) {
          snake.dir = Dir.Right;
        }
        break;

      case p.UP_ARROW:
        if (snake.dir !== Dir.Bottom) {
          snake.dir = Dir.Top;
        }
        break;

      case p.DOWN_ARROW:
        if (snake.dir !== Dir.Top) {
          snake.dir = Dir.Bottom;
        }
        break;
      case p.ENTER:
        restartGame();
        break;
    }
  };

  function reactOnCollision(col: CollisionType) {
    switch (col) {
      case CollisionType.Eat:
        food = makeRandomFood(tableSize);
        const snake2 = { x: snake.x, y: snake.y, dir: snake.dir, next: snake };
        snake = snake2;
        score++;
        p.frameRate(5 + Math.floor(score / 5));
        break;

      case CollisionType.Deadly:
        playing = false;
        restart.show();
        break;
    }
  }

  function drawSnake() {
    const cellSize = p.width / tableSize;
    p.push();
    p.stroke(0);
    if (playing === true) {
      p.fill("green");
    } else {
      p.fill("blue");
    }
    drawSnakeCell(snake, cellSize);
    p.pop();
  }
  function drawSnakeCell(snake: Snake, cellSize: number) {
    if (snake == null) {
      return;
    } else {
      p.rect(snake.x * cellSize, snake.y * cellSize, cellSize, cellSize);
      drawSnakeCell(snake.next, cellSize);
    }
  }

  function drawFood() {
    const cellSize = p.width / tableSize;
    p.push();
    p.stroke(0);
    p.fill("red");
    p.rect(food.x * cellSize, food.y * cellSize, cellSize, cellSize);
    p.pop();
  }
};

function intersectCell(snake: Snake | null, x: number, y: number) {
  if (snake.x === x && snake.y === y) {
    return true;
  } else if (snake.next != null) {
    return intersectCell(snake.next, x, y);
  } else {
    return false;
  }
}

function detectCollision(
  snake: Snake,
  food: Food,
  tableSize: number
): CollisionType {
  if (food.x === snake.x && food.y === snake.y) return CollisionType.Eat;

  if (
    snake.x > tableSize - 1 ||
    snake.x < 0 ||
    snake.y < 0 ||
    snake.y > tableSize - 1
  )
    return CollisionType.Deadly;
  if (snake.next && intersectCell(snake.next, snake.x, snake.y))
    return CollisionType.Deadly;
  return CollisionType.None;
}

new P5(main);
